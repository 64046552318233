class UniqueIdGenerator {
  constructor() {
    this.lastId = 0;
  }

  generate() {
    const now = Date.now();
    if (now <= this.lastId) {
      this.lastId++;
    } else {
      this.lastId = now;
    }
    return this.lastId.toString(36);
  }
}
const uniqueIdGeneratorInstance = new UniqueIdGenerator();

export default uniqueIdGeneratorInstance;
