import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, TextField, Button, Typography, Box } from '@mui/material';
import db from '../db';

function Login({ setAuth }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        'https://carp-tracker.ru/php/auth.php',
        {
          username,
          password
        }
      );
      if (response.data.success) {
        const token = response.data.token;

        // Сохранение логина и токена в локальную базу данных
        await db.auth.clear(); // Очистка старых данных
        await db.auth.add({ username, token });

        setAuth(true);
        navigate('/');
      } else {
        setError('Неверные логин или пароль');
      }
    } catch (error) {
      setError('Ошибка входа. Повторите попытку.');
    }
  };

  return (
    <Container maxWidth="sm">
      <Box mt={8}>
        <Typography variant="h4" gutterBottom>
          Авторизация
        </Typography>
        {error && <Typography color="error">{error}</Typography>}
        <form onSubmit={handleSubmit}>
          <TextField
            label="логин"
            variant="outlined"
            fullWidth
            margin="normal"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <TextField
            label="пароль"
            variant="outlined"
            type="password"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Войти
          </Button>
        </form>
      </Box>
    </Container>
  );
}

export default Login;
