import React from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardActionArea
} from '@mui/material';
import { Link } from 'react-router-dom';
function TournamentList({ tournaments }) {
  return (
    <Box sx={{ p: 2 }}>
      {tournaments.length === 0 ? (
        <Typography variant="h6">Список турниров пустой</Typography>
      ) : (
        tournaments.map((tournament) => (
          <Link
            key={tournament.id}
            to={`/tournament/${tournament.id}`}
            style={{ textDecoration: 'none' }}
          >
            <Card key={tournament.id} sx={{ mb: 2 }}>
              <CardActionArea>
                <CardContent>
                  <Typography variant="h5">{tournament.name}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    Водоем: {tournament.water}
                  </Typography>
                </CardContent>{' '}
              </CardActionArea>
            </Card>
          </Link>
        ))
      )}
    </Box>
  );
}

export default TournamentList;
