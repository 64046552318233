import Dexie from 'dexie';

const db = new Dexie('CarpTrackerDB');
db.version(1).stores({
  actions: '++id, type, distance, quantity, fish_type, time',
  auth: '++id, username, token',
  tournament: '++id, name, water, url',
  requests: '++id, method, endpoint, data, status',
  stage: '++id, cup_id, rods, direction, distance, comment, type, date',
  images: '++id, file, fileUrl, cupId,  sent'
});
export default db;
