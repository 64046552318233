import React, { useEffect, useState } from 'react';
import axios from 'axios';

function TrainerView() {
  const [actions, setActions] = useState([]);

  useEffect(() => {
    const fetchActions = async () => {
      try {
        const response = await axios.get('http://localhost/api.php?action=list');
        setActions(response.data);
      } catch (error) {
        console.error('Ошибка при получении данных', error);
      }
    };

    fetchActions();
  }, []);

  return (
    <div>
      <h2>Trainer View</h2>
      <ul>
        {actions.map((action) => (
          <li key={action.id}>
            {action.type} - {action.distance}m - {action.quantity} - {action.fish_type} - {new Date(action.time).toLocaleString()}
            {/* Здесь можно добавить форму для добавления комментариев или советов */}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default TrainerView;
