import React, { useEffect, useState } from 'react';
import { Button, Modal, TextField, Typography, Box } from '@mui/material';
import { addRequestToQueue } from '../utils/api';
import db from '../db';
import TournamentList from './TournamentList';
import uniqueIdGenerator from '../utils/uniqueIdGenerator';
function Menu() {
  const [open, setOpen] = useState(false);
  const [pondName, setPondName] = useState('');
  const [competitionName, setCompetitionName] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [tournaments, setTournaments] = useState([]);

  useEffect(() => {
    const fetchTournaments = async () => {
      try {
        const allTournaments = await db.tournament.toArray();
        setTournaments(allTournaments);
      } catch (error) {
        console.error('Ошибка при получении турниров:', error);
      }
    };

    fetchTournaments();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log({ pondName, competitionName });

    try {
      const id = uniqueIdGenerator.generate();
      const newTournament = {
        id,
        name: competitionName,
        water: pondName
      };
      setTournaments([...tournaments, newTournament]);
      addRequestToQueue('tournament', 'addTournament.php', newTournament);
      handleClose();
    } catch (error) {
      console.error('Ошибка при добавлении турнира:', error);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        sx={{ position: 'fixed', bottom: 20, right: 20 }}
      >
        Создать сессию
      </Button>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            width: 300,
            bgcolor: 'background.paper',
            borderRadius: 2,
            p: 4,
            boxShadow: 24,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            Создание сессии
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Название соревнований"
              variant="outlined"
              fullWidth
              margin="normal"
              value={competitionName}
              onChange={(e) => setCompetitionName(e.target.value)}
              required
            />

            <TextField
              label="Название водоема"
              variant="outlined"
              fullWidth
              margin="normal"
              value={pondName}
              onChange={(e) => setPondName(e.target.value)}
              required
            />

            <Button type="submit" variant="contained" color="primary" fullWidth>
              Создать
            </Button>
          </form>
        </Box>
      </Modal>

      <TournamentList tournaments={tournaments} />
    </>
  );
}

export default Menu;
