import React, { useEffect, useRef } from 'react';

const MarkerMap = ({ markers, feedMarkers, fishMarkers }) => {
  const canvasRef = useRef(null);
  const numRays = 5;

  const drawMap = (ctx) => {
    const canvas = ctx.canvas;
    const centerX = canvas.width / 2;
    const centerY = canvas.height;
    const scale = 3;
    const maxDistance = 170;

    const angleStart = 70;
    const angleStep = 10;

    ctx.clearRect(0, 0, canvas.width, canvas.height); // Очистка холста

    ctx.strokeStyle = 'lightgrey'; // Устанавливаем цвет линий
    ctx.beginPath();
    for (let i = 0; i < numRays; i++) {
      const angle = ((angleStart + i * angleStep) * Math.PI) / 180;
      const x = centerX + maxDistance * scale * Math.cos(angle);
      const y = centerY - maxDistance * scale * Math.sin(angle);

      ctx.moveTo(centerX, centerY);
      ctx.lineTo(x, y);

      ctx.stroke(); // Рисуем линию
      ctx.fillStyle = 'black'; // Устанавливаем цвет текста
      ctx.fillText(numRays - i, x, y - 10); // Рисуем текст
    }

    for (let i = 1; i <= maxDistance / 10; i++) {
      ctx.beginPath();
      ctx.arc(centerX, centerY, i * 10 * scale, Math.PI, 2 * Math.PI);
      ctx.stroke();

      ctx.fillStyle = 'black'; // Устанавливаем цвет текста
      ctx.fillText(i * 10, centerX + 5, centerY - i * 10 * scale);
    }
  };

  const getColor = (rodNumber) => {
    console.log(rodNumber);
    switch (String(rodNumber)) {
      case '1':
        return 'red';
      case '2':
        return 'green';
      case '3':
        return 'yellow';
      case '4':
        return 'blue';
      default:
        return 'black';
    }
  };
  const addfishMarkers = (ctx, markers) => {
    markers.forEach(({ ray, distance }) => {
      const canvas = ctx.canvas;
      const centerX = canvas.width / 2;
      const centerY = canvas.height;
      const scale = 3;
      const angleStart = 70;
      const angleStep = 10;

      // Проверка и преобразование значений
      const rayNumber = ray;
      const dist = parseInt(distance);

      if (isNaN(rayNumber) || isNaN(dist)) {
        console.error(`Invalid marker data: ray=${ray}, distance=${distance}`);
        return;
      }

      const angle =
        ((angleStart + (numRays - rayNumber) * angleStep) * Math.PI) / 180;
      const x = centerX + dist * scale * Math.cos(angle);
      const y = centerY - dist * scale * Math.sin(angle);

      ctx.beginPath();
      ctx.arc(x - 4, y - 2, 4, 0, 2 * Math.PI);
      ctx.fillStyle = 'black';
      ctx.fill();
      ctx.stroke();

      // Рисуем таймер рядом с маркером
      const timerText = distance;
      ctx.fillStyle = 'black';
      ctx.font = '12px Arial';
    });
  };

  const addMarkers = (ctx, markers) => {
    markers.forEach(({ ray, distance, rodNumber }) => {
      const canvas = ctx.canvas;
      const centerX = canvas.width / 2;
      const centerY = canvas.height;
      const scale = 3;
      const angleStart = 70;
      const angleStep = 10;

      // Проверка и преобразование значений
      const rayNumber = ray;
      const dist = parseInt(distance);

      if (isNaN(rayNumber) || isNaN(dist)) {
        console.error(`Invalid marker data: ray=${ray}, distance=${distance}`);
        return;
      }

      const angle =
        ((angleStart + (numRays - rayNumber) * angleStep) * Math.PI) / 180;
      const x = centerX + dist * scale * Math.cos(angle);
      const y = centerY - dist * scale * Math.sin(angle);

      ctx.beginPath();
      ctx.arc(x, y, 5, 0, 2 * Math.PI);
      ctx.fillStyle = getColor(rodNumber);
      ctx.fill();
      ctx.stroke();

      // Рисуем таймер рядом с маркером
      const timerText = distance;
      ctx.fillStyle = 'black';
      ctx.font = '12px Arial';
      ctx.fillText(timerText, x + 10, y - 10); // Выводим таймер немного справа и выше от маркера
    });
  };

  const addFeedMarkers = (ctx, feedMarkers) => {
    feedMarkers.forEach(({ ray, distance, opacity }) => {
      const canvas = ctx.canvas;
      const centerX = canvas.width / 2;
      const centerY = canvas.height;
      const scale = 3;
      const angleStart = 70;
      const angleStep = 10;

      // Проверка и преобразование значений
      const rayNumber = parseInt(ray);
      const dist = parseInt(distance);
      const alpha = parseFloat(opacity);

      if (isNaN(rayNumber) || isNaN(dist) || isNaN(alpha)) {
        console.error(
          `Invalid feed marker data: ray=${ray}, distance=${distance}, opacity=${opacity}`
        );
        return;
      }
      const angle =
        ((angleStart + (numRays - rayNumber) * angleStep) * Math.PI) / 180;

      const x = centerX + dist * scale * Math.cos(angle);
      const y = centerY - dist * scale * Math.sin(angle);
      const rotation = 0.2 * (rayNumber - 3);
      ctx.save(); // Сохранить текущий контекст
      ctx.translate(x, y + 2); // Переместить контекст к месту рисования овала
      ctx.rotate(rotation); // Повернуть контекст
      ctx.beginPath();
      ctx.ellipse(0, 0, 14, 6, 0, 0, 2 * Math.PI); // Овал с корректировкой поворота
      ctx.fillStyle = `rgba(139, 69, 19, ${alpha})`; // Коричневый цвет с прозрачностью
      ctx.fill();
      ctx.stroke();
      ctx.restore(); // Восстановить контекст
    });
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const handleResize = () => {
      canvas.width = Math.min(window.innerWidth - 30, 550);
      canvas.height = 550;
      drawMap(ctx);
      addFeedMarkers(ctx, feedMarkers);
      addMarkers(ctx, markers);
      addfishMarkers(ctx, fishMarkers);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial setup

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [markers, feedMarkers]); // Добавляем markers, timers и feedMarkers в зависимости useEffect

  return (
    <canvas
      ref={canvasRef}
      style={{ border: '1px solid black', display: 'block' }}
    ></canvas>
  );
};

export default MarkerMap;
