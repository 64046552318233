import db from '../db';
import axios from 'axios';

const SERVER_URL = 'https://carp-tracker.ru/php/';

export const addRequestToQueue = async (method, endpoint, data) => {
  if (method === 'tournament') {
    await db.tournament.add(data);
  }
  if (method === 'stage') {
    await db.stage.add(data);
  }

  await db.requests.add({
    method,
    endpoint,
    data: JSON.stringify(data),
    status: 'pending'
  });
};

export const sendRequests = async () => {
  try {
    // Получаем все запросы со статусом 'pending'
    const requests = await db.requests
      .where('status')
      .equals('pending')
      .toArray();

    // Обрабатываем каждый запрос
    for (const request of requests) {
      try {
        // Отправляем запрос
        await axios({
          method: 'POST',
          url: `${SERVER_URL}${request.endpoint}`,
          data: JSON.parse(request.data) // Убедитесь, что данные верно преобразуются
        });

        // Обновляем статус запроса на 'ok'
        await db.requests.update(request.id, { status: 'ok' });
      } catch (error) {
        console.error(`Ошибка отправки запроса с id ${request.id}:`, error);
      }
    }
  } catch (error) {
    console.error('Ошибка при обработке запросов:', error);
  }

  if (navigator.onLine) {
    const pendingImages = await db.images
      .where('sent')
      .equals('pending')
      .toArray();
    for (const image of pendingImages) {
      try {
        const formData = new FormData();
        formData.append('file', new Blob([image.file]));
        formData.append('id', image.tournamentId);

        const response = await fetch('https://carp-tracker.ru/php/upload.php', {
          method: 'POST',
          body: formData
        });

        const result = await response.json();

        if (response.ok && result.success) {
          await db.images.update(image.id, { sent: 'ok' });
        } else {
          throw new Error(
            result.message || 'Ошибка при загрузке файла на сервер'
          );
        }
      } catch (error) {
        console.error('Ошибка при синхронизации с сервером:', error);
      }
    }
  }
};
export const fetchAndUpdateData = async () => {
  try {
    // Пробуем запросы к серверу
    const response = await axios.get(`${SERVER_URL}getTournaments.php`);
    const tournaments = response.data;

    // Получаем все существующие турниры
    const existingTournaments = await db.tournament.toArray();

    // Создаем объект для быстрого поиска по ID
    const existingIds = new Set(
      existingTournaments.map((tournament) => tournament.id)
    );

    // Создаем массив для добавления новых турниров
    const toAdd = [];
    const toUpdate = [];

    tournaments.forEach((tournament) => {
      if (existingIds.has(tournament.id)) {
        toUpdate.push(tournament);
      } else {
        toAdd.push(tournament);
      }
    });

    // Выполняем добавление и обновление данных
    if (toAdd.length > 0) {
      await db.tournament.bulkAdd(toAdd);
    }

    if (toUpdate.length > 0) {
      await db.tournament.bulkPut(toUpdate);
    }

    // Другие запросы аналогично
    const responseS = await axios.get(`${SERVER_URL}getStage.php`);
    const tournamentsS = responseS.data;

    // Получаем все существующие турниры
    const existingTournamentsS = await db.stage.toArray();

    // Создаем объект для быстрого поиска по ID
    const existingIdsS = new Set(existingTournamentsS.map((stage) => stage.id));

    // Создаем массив для добавления новых турниров
    const toAddS = [];
    const toUpdateS = [];

    tournamentsS.forEach((stage) => {
      if (existingIdsS.has(stage.id)) {
        toUpdateS.push(stage);
      } else {
        toAddS.push(stage);
      }
    });

    // Выполняем добавление и обновление данных
    if (toAddS.length > 0) {
      await db.stage.bulkAdd(toAddS);
    }

    if (toUpdateS.length > 0) {
      await db.stage.bulkPut(toUpdateS);
    }
  } catch (error) {
    console.error('Ошибка при обновлении данных:', error);
    // Локальная база данных будет использоваться, если нет интернета
  }
};
