import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import {
  Typography,
  Box,
  Button,
  FormControl,
  FormGroup,
  Checkbox,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper
} from '@mui/material';
import { addRequestToQueue, fetchAndUpdateData } from '../utils/api';
import db from '../db';
import MarkerMap from './MarkerMap';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NorthWestIcon from '@mui/icons-material/NorthWest';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

const formatTime = (date) => {
  const now = new Date();
  const diff = now - new Date(date);

  const hours = Math.floor(diff / 3600000);
  const minutes =
    Math.floor((diff % 3600000) / 60000) < 10
      ? '0' + Math.floor((diff % 3600000) / 60000)
      : Math.floor((diff % 3600000) / 60000);
  const seconds = Math.floor((diff % 60000) / 1000);

  return `${hours}:${minutes}:${seconds}`;
};

const TournamentDetail = () => {
  const { id } = useParams();
  const [tournament, setTournament] = useState(null);
  const [role, setRole] = useState('coach');
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [open, setOpen] = useState(false);

  const [direction, setDirection] = useState('');
  const [distance, setDistance] = useState('');
  const [rods, setRods] = useState('');
  const [comment, setComment] = useState('');
  const [time, setTime] = useState('');
  const [stage, setStage] = useState([]);
  const [timers, setTimers] = useState({});
  const [openfeed, setOpenfeed] = useState(false);
  const [directionfeed, setDirectionfeed] = useState('');
  const [distancefeed, setDistancefeed] = useState('');
  const [mode, setModel] = useState('online');
  const [commentfeed, setCommentfeed] = useState('');
  const [commentfish, setCommentfish] = useState('');
  const [historytext, sethistorytext] = useState('');
  const [timefeed, setTimefeed] = useState('');
  const [timeffish, settimeffish] = useState('');
  const [markers, setMarkers] = useState([
    { ray: 1, distance: 10, rodNumber: '1' },
    { ray: 2, distance: 10, rodNumber: '2' },
    { ray: 3, distance: 10, rodNumber: '3' },
    { ray: 4, distance: 10, rodNumber: '4' }
  ]);
  const [fishMarkers, setfishMarkers] = useState([
    { ray: '1', distance: '5', opacity: '0' },
    { ray: '1', distance: '5', opacity: '0' },
    { ray: '1', distance: '5', opacity: '0' },
    { ray: '1', distance: '5', opacity: '0' },
    { ray: '1', distance: '5', opacity: '0' },
    { ray: '1', distance: '5', opacity: '0' },
    { ray: '1', distance: '5', opacity: '0' }
  ]);
  const [feedMarkers, setFeedMarkers] = useState([
    { ray: '1', distance: '5', opacity: '0' },
    { ray: '1', distance: '5', opacity: '0' },
    { ray: '1', distance: '5', opacity: '0' },
    { ray: '1', distance: '5', opacity: '0' }
  ]);
  const [historyIndex, setHistoryIndex] = useState(0);
  const [fishChecked, setFishChecked] = useState(false);
  const [sightChecked, setSightChecked] = useState(false);
  const [selectedfisrods, setselectedfisrods] = useState(null);
  const [openFish, onCloseFish] = useState(false);
  const [filterType, setFilterType] = useState('all'); // Тип фильтрации

  const getDirectionIcon = (direction) => {
    // Parse the direction as a float and round to the nearest integer
    const roundedDirection = Math.round(parseFloat(direction));

    // Use a switch statement to return the appropriate icon
    switch (roundedDirection) {
      case 1:
        return <ArrowBackIcon />;
      case 2:
        return <NorthWestIcon />;
      case 3:
        return <ArrowUpwardIcon />;
      case 4:
        return <ArrowOutwardIcon />;
      case 5:
        return <ArrowForwardIcon />;
      default:
        return null;
    }
  };
  useEffect(() => {
    // setMode('online');
  }, []);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const data = await fetchAndUpdateData(id);
  //       if (data && data.length > 0) {
  //         setMarkers(
  //           data.map((item) => ({
  //             ray: item.direction,
  //             distance: parseInt(item.distance, 10),
  //             rodNumber: item.rods
  //           }))
  //         );
  //       }
  //     } catch (error) {
  //       console.error('Ошибка при получении данных:', error);
  //     }
  //   };

  //   if (mode === 'online') {
  //     fetchData();
  //     const intervalId = setInterval(fetchData, 10000);

  //     return () => clearInterval(intervalId);
  //   }
  // }, [id, mode]);

  const handlePrevHistory = () => {
    setHistoryIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };
  const onClosemFish = () => {
    onCloseFish(false);
  };
  const handleNextHistory = () => {
    setHistoryIndex((prevIndex) => Math.min(prevIndex + 1, stage.length - 1));
  };
  useEffect(() => {
    if (mode === 'history' && stage.length > 0) {
      // Получаем текущий этап
      const currentStage = stage[historyIndex];

      // Выводим время текущего historyIndex в консоль
      sethistorytext('время ' + currentStage.date);
      //console.log(currentStage);

      // Создаем объект для отслеживания уникальных номеров удочек
      const uniqueRodNumbers = new Set([1, 2, 3, 4]);
      const seenRodNumbers = new Set();

      // Найти уникальные удочки
      const previousStages = stage
        .filter((_, index) => index < historyIndex) // Удочки с индексами меньше historyIndex
        .filter((item) => uniqueRodNumbers.has(item.rods)) // Удочки с номерами 1, 2, 3, 4
        .sort((a, b) => {
          const indexA = stage.indexOf(a);
          const indexB = stage.indexOf(b);
          return (
            Math.abs(historyIndex - indexA) - Math.abs(historyIndex - indexB)
          );
        }) // Сортируем по близости к historyIndex
        .filter((item) => {
          if (currentStage.rods === item.rods) return false;
          if (!seenRodNumbers.has(item.rods)) {
            seenRodNumbers.add(item.rods);
            return true;
          }
          return false;
        }) // Исключаем дублирующиеся номера удочек
        .slice(0, 3) // Ограничиваем количество до 3
        .map((item) => ({
          ray: item.direction,
          distance: parseInt(item.distance, 10),
          rodNumber: item.rods
        }));
      // console.log(previousStages);
      // Обновляем состояние markers, включая текущую удочку и предыдущие
      setMarkers([
        ...previousStages,
        {
          ray: currentStage.direction,
          distance: parseInt(currentStage.distance, 10),
          rodNumber: currentStage.rods
        }
      ]);

      // Получаем корм для текущего historyIndex
      const feedStages = stage
        .filter(
          (item) => item.type === 'корм' && stage.indexOf(item) <= historyIndex
        )
        .map((item) => ({
          ray: item.direction || '0', // Обеспечиваем, что ray не пустой
          distance: parseInt(item.distance, 10) || 0,
          opacity:
            (new Date() - new Date(item.date)) / 3600000 > 15 ? '0.3' : '1'
        }));

      // Обновляем состояние feedMarkers
      setFeedMarkers(feedStages);
    }
  }, [historyIndex, mode, stage]);

  // useEffect(() => {
  //   if (mode === 'history' && stage.length > 0) {
  //     // Получаем текущий этап
  //     const currentStage = stage[historyIndex];

  //     // Выводим время текущего historyIndex в консоль
  //     sethistorytext('время ' + currentStage.date);
  //     console.log(currentStage);
  //     // Получаем 3 предыдущих удочки (если они есть)
  //     const previousStages = stage
  //       .slice(Math.max(0, historyIndex - 3), historyIndex)
  //       .map((item) => ({
  //         ray: item.direction,
  //         distance: parseInt(item.distance, 10),
  //         rodNumber: item.rods
  //       }));

  //     // Обновляем состояние markers, включая текущую удочку и предыдущие
  //     setMarkers([
  //       ...previousStages,
  //       {
  //         ray: currentStage.direction,
  //         distance: parseInt(currentStage.distance, 10),
  //         rodNumber: currentStage.rods
  //       }
  //     ]);

  //     // // Получаем корм для текущего historyIndex
  //     // const feedStages = stage
  //     //   .filter(
  //     //     (item) => item.type === 'корм' && stage.indexOf(item) <= historyIndex
  //     //   )
  //     //   .map((item) => ({
  //     //     ray: item.direction || '0', // Обеспечиваем, что ray не пустой
  //     //     distance: parseInt(item.distance, 10) || 0,
  //     //     opacity:
  //     //       (new Date() - new Date(item.date)) / 3600000 > 15 ? '0.3' : '1'
  //     //   }));

  //     // // Обновляем состояние feedMarkers
  //     // setFeedMarkers(feedStages);
  //   }
  // }, [historyIndex, mode, stage]);
  const handleClick = useCallback((rod) => {
    // console.log(`Clicked on rod: ${rod}`);
    settimeffish(getLocalDateTime());
    setSightChecked(false);
    setFishChecked(false);
    setCommentfish('');
    setselectedfisrods(rod);
    onCloseFish(true);
    // Реализуйте логику обработки клика здесь
  }, []);
  const handleSaveFis = async () => {
    // console.log(selectedfisrods);
    if (selectedfisrods) {
      const lastRecord = stage
        .filter((item) => item.rods == selectedfisrods)
        .pop();
      console.log('Последняя запись для удочки:', lastRecord);

      const addStage = {
        cup_id: id,
        rods: selectedfisrods,
        direction: lastRecord.direction,
        distance: lastRecord.distance,
        comment: commentfish,
        type: 'рыба',
        date: timeffish
      };
      try {
        setStage((prevStage) => [...prevStage, addStage]);

        addRequestToQueue('stage', 'addStage.php', addStage);
      } catch (error) {
        console.error('Ошибка при добавлении перезаброса:', error);
      }

      onClosemFish();
    }
  };

  useEffect(() => {
    const fetchTournament = async () => {
      try {
        const result = await db.tournament.get(id);
        setPreviewUrl(result.url);
        setTournament(result);
      } catch (error) {
        console.error('Ошибка при получении турнира:', error);
      }
    };

    const fetchStage = async () => {
      try {
        const result = await db.stage.where({ cup_id: id }).toArray();

        setStage(result);
        const newTimers = {};
        result.forEach((item) => {
          newTimers[item.rods] = item.date;
        });
        setTimers(newTimers);
      } catch (error) {
        console.error('Ошибка при получении stage:', error);
      }
    };

    fetchTournament();
    fetchStage();
  }, [id]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const updatedTimers = {};
      for (const [key, value] of Object.entries(timers)) {
        updatedTimers[key] = formatTime(value);
      }
      setTimers(updatedTimers);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timers]);
  const handleFishChange = (event) => {
    setFishChecked(event.target.checked);
  };

  const handleSightChange = (event) => {
    setSightChecked(event.target.checked);
  };
  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    const objectUrl = URL.createObjectURL(file);
    setPreviewUrl(objectUrl);

    setUploading(true);
    await handleSaveToDb(file);
    setUploading(false);
  };

  const handleSaveToDb = async (file) => {
    const reader = new FileReader();
    reader.onload = async () => {
      const arrayBuffer = reader.result;
      const blob = new Blob([arrayBuffer]);
      const blobUrl = URL.createObjectURL(blob);
      try {
        await db.images.add({
          file: arrayBuffer,
          fileUrl: blobUrl,
          sent: 'pending'
        });
      } catch (error) {
        console.error('Ошибка при сохранении в базу данных:', error);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  function getLocalDateTime() {
    const now = new Date();
    const offset = now.getTimezoneOffset() * 60000;
    const localISOTime = new Date(now.getTime() - offset).toISOString();
    return localISOTime.slice(0, 19);
  }
  const handleClickOpenfeed = () => {
    setTimefeed(getLocalDateTime());
    setDirectionfeed('');
    setDistancefeed('');

    setCommentfeed('');
    setOpenfeed(true);
  };

  const handleClickOpen = () => {
    setTime(getLocalDateTime());
    setDirection('');
    setDistance('');
    setRods('');
    setComment('');
    setOpen(true);
  };

  const handleRodsChange = (value) => {
    setRods(value);
  };
  const handleClosefeed = () => {
    setOpenfeed(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSavefeed = async () => {
    const newMarker = {
      ray: directionfeed,
      distance: distancefeed,
      opacity: '1'
    };
    // Удаляем старые маркеры с этим удилищем
    setFeedMarkers((prevMarkers) => [...prevMarkers, newMarker]);

    const addStage = {
      cup_id: id,
      rods: 1,
      direction: directionfeed,
      distance: distancefeed,
      comment: commentfeed,
      type: 'корм',
      date: timefeed
    };

    try {
      setStage((prevStage) => [...prevStage, addStage]);

      addRequestToQueue('stage', 'addStage.php', addStage);
    } catch (error) {
      console.error('Ошибка при добавлении перезаброса:', error);
    }

    handleClosefeed();
  };
  const handleFilterChange = (event) => {
    setFilterType(event.target.value);
  };

  const filteredStage = stage.filter((item) => {
    if (filterType === 'all') return true;
    if (filterType === 'fish' && item.type == 'рыба') return true;
    if (filterType === 'recast' && item.type == 'перезаброс') return true;
    if (filterType === 'feed' && item.type == 'корм') return true;
    return false;
  });

  const handleSave = async () => {
    const newMarker = {
      ray: direction,
      distance: parseInt(distance, 10),
      rodNumber: rods
    };

    // Удаляем старые маркеры с этим удилищем
    setMarkers((prevMarkers) => [
      ...prevMarkers.filter((marker) => marker.rodNumber !== rods),
      newMarker
    ]);

    const addStage = {
      cup_id: id,
      rods,
      direction,
      distance,
      comment,
      type: 'перезаброс',
      date: time
    };

    try {
      setStage((prevStage) => [...prevStage, addStage]);
      setTimers((prevTimers) => ({
        ...prevTimers,
        [rods]: time
      }));

      addRequestToQueue('stage', 'addStage.php', addStage);
    } catch (error) {
      console.error('Ошибка при добавлении перезаброса:', error);
    }

    handleClose();
  };
  useEffect(() => {
    if (stage.length > 0) {
      // Устанавливаем historyIndex на последний элемент в stage
      setHistoryIndex(stage.length - 1);
    }
  }, [stage]);

  useEffect(() => {
    if (mode === 'online') {
      const intervalId = setInterval(getTournamentData, 5000);
      return () => clearInterval(intervalId);
    }
  }, [stage, mode]);

  const getTournamentData = async () => {
    await fetchAndUpdateData();

    try {
      const result = await db.stage.where({ cup_id: id }).toArray();
      setStage(result);
    } catch (error) {
      console.error('Ошибка при получении stage:', error);
    }

    await setMarkers([
      {
        ray: latestStages[1]?.direction,
        distance: latestStages[1]?.distance,
        rodNumber: '1'
      },
      {
        ray: latestStages[2]?.direction,
        distance: latestStages[2]?.distance,
        rodNumber: '2'
      },
      {
        ray: latestStages[3]?.direction,
        distance: latestStages[3]?.distance,
        rodNumber: '3'
      },
      {
        ray: latestStages[4]?.direction,
        distance: latestStages[4]?.distance,
        rodNumber: '4'
      }
    ]);

    const feedMarkers = Object.keys(latestStagesFeed).map((direction) => ({
      ray: latestStagesFeed[direction]?.direction || '0',
      distance: latestStagesFeed[direction]?.distance || '0',
      opacity:
        (new Date() - new Date(latestStagesFeed[direction]?.date)) / 3600000 >
        15
          ? '0.3'
          : '1'
    }));
    await setFeedMarkers(feedMarkers);

    const fishMarkerss = Object.keys(latestStagesFish).map((direction) => ({
      ray: latestStagesFish[direction]?.direction || '0',
      distance: latestStagesFish[direction]?.distance || '0',
      opacity:
        (new Date() - new Date(latestStagesFish[direction]?.date)) / 3600000 >
        15
          ? '0.3'
          : '1'
    }));
    // console.log(fishMarkerss);
    // console.log(feedMarkers);
    await setfishMarkers(fishMarkerss);
  };
  if (!tournament) {
    return <Typography variant="h6">Загрузка...</Typography>;
  }

  const getColor = (rodNumber) => {
    switch (rodNumber) {
      case '1':
        return 'red';
      case '2':
        return 'green';
      case '3':
        return 'yellow';
      case '4':
        return 'blue';
      default:
        return 'black';
    }
  };
  const setModeonlain = () => {
    // console.log('setModeonlain');
    sethistorytext('');
    setModel('online');
  };
  const setModeoflain = () => {
    //console.log('setModeoflain');
    setModel('history');
  };

  const latestStages = stage.reduce((acc, item) => {
    if (item.type === 'перезаброс') {
      acc[item.rods] = item;
    }
    return acc;
  }, {});
  const latestStagesFeed = stage.reduce((acc, item) => {
    if (item.type === 'корм') {
      acc[item.id] = item;
    }
    return acc;
  }, {});

  const latestStagesFish = stage.reduce((acc, item) => {
    if (item.type === 'рыба') {
      acc[item.id] = item;
    }
    return acc;
  }, {});

  return (
    <>
      <Box sx={{ p: 2, textAlign: 'center' }}>
        <Typography variant="h5" component="h1" gutterBottom>
          {tournament.name}
        </Typography>
        <Typography variant="subtitle1">Водоем: {tournament.water}</Typography>
      </Box>
      <Box sx={{ p: 2 }}>
        <FormControl>
          <FormLabel id="demo-row-radio-buttons-group-label">
            Режим работы:
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={role}
            onChange={handleRoleChange}
          >
            <FormControlLabel
              value="athlete"
              control={<Radio />}
              label="Спортсмен"
            />
            <FormControlLabel
              value="coach"
              control={<Radio />}
              label="Тренер"
            />
          </RadioGroup>
        </FormControl>
      </Box>
      {previewUrl && (
        <Box sx={{ textAlign: 'center' }}>
          <img
            src={previewUrl}
            alt="Preview"
            style={{
              maxWidth: '100%',
              maxHeight: '400px',
              objectFit: 'contain'
            }}
          />
        </Box>
      )}
      {role === 'athlete' && (
        <Box sx={{ p: 2, textAlign: 'center' }}>
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="upload-file"
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="upload-file">
            <Button component="span" size="small" disabled={uploading}>
              Выбрать изображение
            </Button>
          </label>
        </Box>
      )}
      <Box sx={{ p: 1 }}>
        {['1', '2', '3', '4'].map((rod) => (
          <React.Fragment key={rod}>
            <Box sx={{ width: '100%' }} onClick={() => handleClick(rod)}>
              <Paper
                sx={{
                  mb: 2,
                  width: '100%',
                  maxWidth: 500,
                  display: 'flex',
                  alignItems: 'center',
                  padding: 1
                }}
              >
                <Box
                  sx={{
                    width: '90%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingLeft: 1,
                    paddingRight: 1
                  }}
                >
                  <Box
                    sx={{
                      width: '10%',
                      backgroundColor: getColor(rod),
                      color: 'white',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: 1
                    }}
                  >
                    {rod}
                  </Box>
                  <Typography variant="body2">
                    {latestStages[rod]
                      ? getDirectionIcon(latestStages[rod].direction)
                      : 'Нет данных'}
                  </Typography>
                  <Typography variant="body1">
                    {latestStages[rod] ? latestStages[rod].distance : ' '}м.
                  </Typography>
                  <Typography variant="body2">
                    <AlarmOnIcon />
                    {latestStages[rod]
                      ? formatTime(latestStages[rod].date)
                      : 'Нет данных'}{' '}
                  </Typography>
                </Box>
              </Paper>
              <Typography variant="body2">
                {latestStages[rod] ? latestStages[rod].comment : ''}
              </Typography>
            </Box>
          </React.Fragment>
        ))}
      </Box>
      {role === 'athlete' && (
        <Box sx={{ mt: 2, '& button': { m: 1 }, textAlign: 'center' }}>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={handleClickOpenfeed}
          >
            корм
          </Button>
          <Button variant="contained" size="large" onClick={handleClickOpen}>
            перезаброс
          </Button>
        </Box>
      )}
      {role !== 'athlete' && (
        <Box sx={{ p: 1, textAlign: 'center' }}>
          <MarkerMap
            markers={markers}
            feedMarkers={feedMarkers}
            fishMarkers={fishMarkers}
          />

          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={mode === 'online'}
                  onChange={setModeonlain}
                  name="online"
                  color="primary"
                />
              }
              label="Онлайн"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={mode === 'history'}
                  onChange={setModeoflain}
                  name="history"
                  color="primary"
                />
              }
              label="История"
            />
          </FormGroup>
          {mode === 'history' && (
            <Box display="flex" alignItems="center" mt={2}>
              <Typography variant="body2">{historytext}</Typography>
              <Button
                variant="contained"
                onClick={handlePrevHistory}
                disabled={historyIndex === 0}
              >
                Назад
              </Button>
              <Typography variant="body1" sx={{ mx: 2 }}>
                {`${historyIndex + 1} из ${stage.length}`}
              </Typography>
              <Button
                variant="contained"
                onClick={handleNextHistory}
                disabled={historyIndex === stage.length - 1}
              >
                Вперед
              </Button>
            </Box>
          )}
        </Box>
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Перезаброс</DialogTitle>
        <DialogContent>
          <Box sx={{ textAlign: 'center' }}>
            <img
              src={previewUrl}
              alt="Preview"
              style={{
                maxWidth: '100%',
                maxHeight: '400px',
                objectFit: 'contain'
              }}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Button
              variant="contained"
              sx={{ backgroundColor: 'red', color: 'white', mr: 1 }}
              onClick={() => handleRodsChange('1')}
            >
              1
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: 'green', color: 'white', mr: 1 }}
              onClick={() => handleRodsChange('2')}
            >
              2
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: 'yellow', color: 'black', mr: 1 }}
              onClick={() => handleRodsChange('3')}
            >
              3
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: 'blue', color: 'white' }}
              onClick={() => handleRodsChange('4')}
            >
              4
            </Button>
            <TextField
              margin="dense"
              label="удилище"
              type="tel"
              fullWidth
              variant="standard"
              value={rods}
              onChange={(e) => setRods(e.target.value)}
            />
          </Box>
          <TextField
            margin="dense"
            label="Направление"
            type="tel"
            fullWidth
            variant="standard"
            value={direction}
            onChange={(e) => setDirection(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Дистанция"
            type="tel"
            fullWidth
            variant="standard"
            value={distance}
            onChange={(e) => setDistance(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Комментарий"
            type="text"
            fullWidth
            variant="standard"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Время"
            type="datetime-local"
            fullWidth
            variant="standard"
            value={time}
            onChange={(e) => setTime(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Отмена</Button>
          <Button onClick={handleSave}>Сохранить</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openfeed} onClose={handleClosefeed}>
        <DialogTitle>Корм</DialogTitle>
        <DialogContent>
          <Box sx={{ textAlign: 'center' }}>
            <img
              src={previewUrl}
              alt="Preview"
              style={{
                maxWidth: '100%',
                maxHeight: '400px',
                objectFit: 'contain'
              }}
            />
          </Box>
          <Box sx={{ mt: 2 }}></Box>
          <TextField
            margin="dense"
            label="Направление"
            type="tel"
            fullWidth
            variant="standard"
            value={directionfeed}
            onChange={(e) => setDirectionfeed(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Дистанция"
            type="tel"
            fullWidth
            variant="standard"
            value={distancefeed}
            onChange={(e) => setDistancefeed(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Комментарий"
            type="text"
            fullWidth
            variant="standard"
            value={commentfeed}
            onChange={(e) => setCommentfeed(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Время"
            type="datetime-local"
            fullWidth
            variant="standard"
            value={timefeed}
            onChange={(e) => setTimefeed(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosefeed}>Отмена</Button>
          <Button onClick={handleSavefeed}>Сохранить</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openFish} onClose={onClosemFish}>
        <DialogTitle>Поклевки</DialogTitle>
        <DialogContent>
          <Box sx={{ p: 2 }}>
            <FormControlLabel
              control={
                <Checkbox checked={fishChecked} onChange={handleFishChange} />
              }
              label="Рыба"
            />
            <FormControlLabel
              control={
                <Checkbox checked={sightChecked} onChange={handleSightChange} />
              }
              label="Сход"
            />
            {/* <Typography variant="body2">
              Комментарий: {rodData.comment || 'Нет данных'}
            </Typography>
           */}
            <TextField
              margin="dense"
              label="Комментарий"
              type="text"
              fullWidth
              variant="standard"
              value={commentfish}
              onChange={(e) => setCommentfish(e.target.value)}
            />
            <TextField
              margin="dense"
              label="Время"
              type="datetime-local"
              fullWidth
              variant="standard"
              value={timeffish}
              onChange={(e) => settimeffish(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClosemFish}>Закрыть</Button>
          <Button onClick={handleSaveFis}>Сохранить</Button>
        </DialogActions>
      </Dialog>
      {role !== 'athlete' && (
        <Box sx={{ mt: 4 }}>
          <FormControl sx={{ mb: 2, width: '100%' }}>
            <InputLabel>Фильтр</InputLabel>
            <Select
              value={filterType}
              onChange={handleFilterChange}
              label="Фильтр"
            >
              <MenuItem value="all">Все</MenuItem>
              <MenuItem value="fish">Рыба</MenuItem>
              <MenuItem value="recast">Перезабросы</MenuItem>
              <MenuItem value="feed">Корм</MenuItem>
            </Select>
          </FormControl>
          <TableContainer sx={{ maxHeight: 400 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Дист.</TableCell>
                  <TableCell>Время</TableCell>
                  <TableCell>Комм.</TableCell>
                  <TableCell>Напр.</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredStage
                  .slice() // Создаем копию массива для избежания изменения оригинального массива
                  .reverse() // Обратный порядок
                  .map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.distance} м.</TableCell>
                      <TableCell>{formatTime(item.date)}</TableCell>
                      <TableCell>{item.comment}</TableCell>
                      <TableCell>{getDirectionIcon(item.direction)}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </>
  );
};

export default TournamentDetail;
