import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from 'react-router-dom';

import { sendRequests, fetchAndUpdateData } from './utils/api';
import Header from './components/Header';
import Menu from './components/Menu';
import ActionList from './components/ActionList';
import TrainerView from './components/TrainerView';
import TournamentDetail from './components/TournamentDetail';
import Login from './components/Login';
import db from './db';
function App() {
  const [auth, setAuth] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const authData = await db.auth.toArray();
        if (authData.length > 0) {
          setAuth(true);
          // Обновите данные с сервера
          await fetchAndUpdateData();
        } else {
          setAuth(false);
        }
      } catch (error) {
        console.error('Ошибка при проверке аутентификации:', error);
        setAuth(false);
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(sendRequests, 5000);
    return () => clearInterval(intervalId);
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Или любой компонент загрузки
  }
  return (
    <Router basename="/">
      <div className="App">
        <Header />
        <Routes>
          <Route path="/login" element={<Login setAuth={setAuth} />} />
          <Route
            path="/"
            element={auth ? <Menu /> : <Navigate to="/login" />}
          />
          <Route
            path="/actions"
            element={auth ? <ActionList /> : <Navigate to="/login" />}
          />
          <Route
            path="/trainer"
            element={auth ? <TrainerView /> : <Navigate to="/login" />}
          />
          <Route path="/tournament/:id" element={<TournamentDetail />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
